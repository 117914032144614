import common from './reducers/common';
import home from './reducers/home';
import blogList from './reducers/blogList';
import blog from './reducers/blog';
import auth from './reducers/auth';
import settings from './reducers/settings';
import editor from './reducers/editor';
import post from './reducers/post';
import postList from './reducers/postList';
import postEditor from './reducers/postEditor';
import community from './reducers/community';
// import profile from './reducers/profile';

import author from './reducers/author';
// import tag from './reducers/tag';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
  common,
  home,
  blogList,
  blog,
  auth,
  settings,
  editor,
  community,
  post,
  postList,
  postEditor,

  // profile,
  author,
  // tag,
  router: connectRouter(history)
});
