import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT = 'http://127.0.0.1:8000/api';
const API_ROOT = 'https://www.xn--2gbujy.com/api';


const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Token ${token}`);
  }
}

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody)
};

const Auth = {
  current: () =>
    requests.get('/user'),
  login: (email, password) =>
    requests.post('/users/login', { user: { email, password } }),
  register: (username, email,role, password) =>
    requests.post('/users', { user: { username, email,role, password } }),
  save: user =>
    requests.put('/user', { user }),
  getAuthors:()=>
    requests.get('/users/authors')
};

const Tags = {
  getAll: () => requests.get('/tags'),
  getSingle:tag=>requests.get(`/tags/${tag}`),
  follow_tag:tag=>requests.post(`/profiles/${tag}/followtag`),
  unfollow_tag:tag=>requests.del(`/profiles/${tag}/followtag`),
};

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlug = blog => Object.assign({}, blog, { slug: undefined })
const Blogs = {
  all: page =>
    requests.get(`/blogs?${limit(6, page)}`),
  byAuthor: (author, page) =>
    requests.get(`/blogs?author=${encode(author)}&${limit(6, page)}`),
  byTag: (tag, page) =>
    requests.get(`/blogs?tag=${encode(tag)}&${limit(6, page)}`),
  del: slug =>
    requests.del(`/blogs/${slug}`),
  favorite: slug =>
    requests.post(`/blogs/${slug}/favorite`),
  favoritedBy: (author, page) =>
    requests.get(`/blogs?favorited=${encode(author)}&${limit(6, page)}`),
  feed: () =>
    requests.get('/blogs/feed/?limit=6&offset=0'),
  get: slug =>
    requests.get(`/blogs/${slug}`),
  unfavorite: slug =>
    requests.del(`/blogs/${slug}/favorite`),
  update: blog =>
    requests.put(`/blogs/${blog.slug}`, { blog: omitSlug(blog) }),
  create: blog =>
    requests.post('/blogs', { blog })
};

const omitSlugPost = post => Object.assign({}, post, { slug: undefined })
const Posts = {
  all: page =>
    requests.get(`/posts?${limit(6, page)}`),
  byAuthor: (author, page) =>
    requests.get(`/posts?author=${encode(author)}&${limit(6, page)}`),
  byTag: (tag, page) =>
    requests.get(`/posts?tag=${encode(tag)}&${limit(6, page)}`),
  del: slug =>
    requests.del(`/posts/${slug}`),
  favorite: slug =>
    requests.post(`/posts/${slug}/favorite`),
  favoritedBy: (author, page) =>
    requests.get(`/posts?favorited=${encode(author)}&${limit(6, page)}`),
  feed: () =>
    requests.get('/posts/feed/?limit=6&offset=0'),
  get: slug =>
    requests.get(`/posts/${slug}`),
  unfavorite: slug =>
    requests.del(`/posts/${slug}/favorite`),
  update: post =>
    requests.put(`/posts/${post.slug}`, { post: omitSlugPost(post) }),
  create: post =>
    requests.post('/posts', { post })
};

const Comments = {
  create: (slug, comment) =>
    requests.post(`/blogs/${slug}/comments`, { comment }),
  delete: (slug, commentId) =>
    requests.del(`/blogs/${slug}/comments/${commentId}`),
  forBlog: slug =>
    requests.get(`/blogs/${slug}/comments`)
};

const PostComments = {
  create: (slug, post_comment) =>
    requests.post(`/posts/${slug}/post_comments`, { post_comment }),
  delete: (slug, commentId) =>
    requests.del(`/posts/${slug}/post_comments/${commentId}`),
  forPost: slug =>
    requests.get(`/posts/${slug}/post_comments`)
};

const Profile = {
  follow: username =>
    requests.post(`/profiles/${username}/follow`),
  get: username =>
    requests.get(`/profiles/${username}`),
  unfollow: username =>
    requests.del(`/profiles/${username}/follow`)
};

const Contactus = {
  submit: contactus =>
    requests.post(`/contactus`, { contactus }),
};

const Joinus = {
  submit: joinus =>
    requests.post(`/joinus`, { joinus }),
};

export default {
  Blogs,
  Posts,
  Auth,
  Comments,
  PostComments,
  Profile,
  Tags,
  Contactus,
  Joinus,
  setToken: _token => { token = _token; }
};
