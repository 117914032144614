import {
  FETCH_AUTHORS_LIST
} from '../constants/actionTypes';

let defaultState = {
  authors: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_AUTHORS_LIST:
      console.log(action.payload.users)
      return {
        ...state,
        authors: action.payload ? action.payload.users : []
      };
    
    default:
      return state;
  }
};
