export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const BLOG_SUBMITTED = 'BLOG_SUBMITTED';
export const POST_SUBMITTED = 'POST_SUBMITTED';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const DELETE_BLOG = 'DELETE_BLOG';
export const DELETE_POST = 'DELETE_POST';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const COMMUNITY_PAGE_LOADED = 'COMMUNITY_PAGE_LOADED';
export const COMMUNITY_PAGE_UNLOADED = 'COMMUNITY_PAGE_UNLOADED';
export const BLOG_PAGE_LOADED = 'BLOG_PAGE_LOADED';
export const BLOG_PAGE_UNLOADED = 'BLOG_PAGE_UNLOADED';
export const POST_PAGE_LOADED = 'POST_PAGE_LOADED';
export const POST_PAGE_UNLOADED = 'POST_PAGE_UNLOADED';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const ADD_POSTCOMMENT = 'ADD_POSTCOMMENT';
export const DELETE_POSTCOMMENT = 'DELETE_POSTCOMMENT';
export const BLOG_FAVORITED = 'BLOG_FAVORITED';
export const BLOG_UNFAVORITED = 'BLOG_UNFAVORITED';
export const POST_FAVORITED = 'POST_FAVORITED';
export const POST_UNFAVORITED = 'POST_UNFAVORITED';
export const SET_PAGE = 'SET_PAGE';
export const POSTS_SET_PAGE = 'POSTS_SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const POSTEDITOR_PAGE_LOADED = 'POSTEDITOR_PAGE_LOADED';
export const POSTEDITOR_PAGE_UNLOADED = 'POSTEDITOR_PAGE_UNLOADED';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const UPDATE_FIELD_POSTEDITOR = 'UPDATE_FIELD_POSTEDITOR';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const PROFILE_FAVORITES_PAGE_UNLOADED = 'PROFILE_FAVORITES_PAGE_UNLOADED';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';
export const FETCH_AUTHORS_LIST = 'FETCH_AUTHORS_LIST';
export const TAGS_PAGE_LOADED = 'TAGS_PAGE_LOADED';
export const TAGS_PAGE_UNLOADED = 'TAGS_PAGE_UNLOADED';
export const GET_SINGLE_TAG = 'GET_SINGLE_TAG';
export const FOLLOW_TAG = 'FOLLOW_TAG';
export const UNFOLLOW_TAG = 'UNFOLLOW_TAG';
