import {
  BLOG_PAGE_LOADED,
  BLOG_PAGE_UNLOADED,
  ADD_COMMENT,
  DELETE_COMMENT
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case BLOG_PAGE_LOADED:
      return {
        ...state,
        blog: action.payload[0].blog,
        comments: action.payload[1].comments
      };
    case BLOG_PAGE_UNLOADED:
      return {};
    case ADD_COMMENT:
      return {
        ...state,
        commentErrors: action.error ? action.payload.errors : null,
        comments: action.error ?
          null :
          ([action.payload.comment]).concat(state.comments || [])
      };
    case DELETE_COMMENT:
      const commentId = action.commentId
      return {
        ...state,
        comments: state.comments.filter(comment => comment.id !== commentId)
      };
    default:
      return state;
  }
};
