export const redText = "#ef5350"
export const headerBackground = "#F4F1EE"
export const zaiti = "#A0A58D"

export const checkboxToText = {
    defaultInline1: "صحة طفلي",
    defaultInline2: "الرضاعة والتغذية",
    defaultInline3: "تربية طفلي",
    defaultInline4: "العناية بطفلي",
    defaultInline5: "الأحكام الشرعية",
    defaultInline6: "أدب طفلي",
    defaultInline7: "مراجع طفلي",
}

export const community_checkboxToText = {
    defaultInline1: "صحة طفلي",
    defaultInline2: "الرضاعة والتغذية",
    defaultInline3: "تربية طفلي",
    defaultInline4: "العناية بطفلي",
    defaultInline5: "مستلزمات طفلي",
    defaultInline6: "أخرى",
}

export const joinus_checkboxToText = {
    joinus_defaultInline1: "كتابة محتوى متخصص",
    joinus_defaultInline2: "ترجمة",
    joinus_defaultInline3: "تصميم",
    joinus_defaultInline4: "برمجة ودعم فني",
    joinus_defaultInline5: "أخرى",
}

export const getReadingTime = (readingTime) => {
    readingTime = parseInt(readingTime)
    if (readingTime === 1) {
        return "دقيقة واحدة للقراءة";
    } else if (readingTime === 2) {
        return "دقيقتان للقراءة";
    } else if (readingTime > 2 && readingTime < 11) {
        return readingTime + " دقائق للقراءة";
    } else {
        return readingTime + " دقيقة للقراءة";
    }
}

export function isFileImage(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
 
    return file && acceptedImageTypes.includes(file['type'])
}

