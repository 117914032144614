import {
  POST_PAGE_LOADED,
  POST_PAGE_UNLOADED,
  ADD_POSTCOMMENT,
  DELETE_POSTCOMMENT
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case POST_PAGE_LOADED:
      return {
        ...state,
        post: action.payload[0].post,
        comments: action.payload[1].post_comments
      };
    case POST_PAGE_UNLOADED:
      return {};
    case ADD_POSTCOMMENT:
      return {
        ...state,
        commentErrors: action.error ? action.payload.errors : null,
        comments: action.error ?
          null :
          (state.comments || []).concat([action.payload.post_comment])
      };
    case DELETE_POSTCOMMENT:
      const commentId = action.commentId
      return {
        ...state,
        comments: state.comments.filter(comment => comment.id !== commentId)
      };
    default:
      return state;
  }
};
