import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  BLOG_SUBMITTED,
  POST_SUBMITTED,
  SETTINGS_SAVED,
  LOGIN,
  REGISTER,
  DELETE_BLOG,
  DELETE_POST,
  BLOG_PAGE_UNLOADED,
  POST_PAGE_UNLOADED,
  EDITOR_PAGE_UNLOADED,
  POSTEDITOR_PAGE_UNLOADED,
  HOME_PAGE_UNLOADED,
  COMMUNITY_PAGE_UNLOADED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED,
  SETTINGS_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED
} from '../constants/actionTypes';

const defaultState = {
  appName: '6fly',
  token: null,
  viewChangeCounter: 0
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload.user : null
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    case LOGOUT:
      return { ...state, redirectTo: '/', token: null, currentUser: null };
    case BLOG_SUBMITTED:
      const redirectUrl = `/blog/${action.payload.blog.slug}`;
      return { ...state, redirectTo: redirectUrl };
    case POST_SUBMITTED:
      const redirectPostUrl = `/post/${action.payload.post.slug}`;
      return { ...state, redirectTo: redirectPostUrl };
    case SETTINGS_SAVED:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        currentUser: action.error ? null : action.payload.user
      };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        token: action.error ? null : action.payload.user.token,
        currentUser: action.error ? null : action.payload.user
      };
    case DELETE_BLOG:
      return { ...state, redirectTo: '/' };
    case DELETE_POST:
      return { ...state, redirectTo: '/' };
    case BLOG_PAGE_UNLOADED:
    case POST_PAGE_UNLOADED:
    case EDITOR_PAGE_UNLOADED:
    case POSTEDITOR_PAGE_UNLOADED:
    case HOME_PAGE_UNLOADED:
    case COMMUNITY_PAGE_UNLOADED:
    case PROFILE_PAGE_UNLOADED:
    case PROFILE_FAVORITES_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    default:
      return state;
  }
};
