import agent from '../agent';
import Header from './Header';

import { Route, Switch } from 'react-router-dom';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { APP_LOAD, REDIRECT } from '../constants/actionTypes';
import { store } from '../store';
import { push } from 'connected-react-router';
import {Helmet} from "react-helmet";

import { headerBackground } from '../constants/colors'
import { web_title, web_desc, web_url } from '../constants/websiteVars'
import {
  MDBFooter, MDBContainer, MDBRow, MDBCol,
  MDBIcon,
} from "mdbreact";

const Home = React.lazy(() => import('../components/Home'));
const Blog = React.lazy(() => import('../components/Blog'));
const Login = React.lazy(() => import('../components/Login'));
const Register = React.lazy(() => import('../components/Register'));
const AuthorRegister = React.lazy(() => import('../components/AuthorRegister'));
const Settings = React.lazy(() => import('../components/Settings'));
const Editor = React.lazy(() => import('../components/Editor'));
const Post = React.lazy(() => import('../components/Post'));
const PostEditor = React.lazy(() => import('../components/PostEditor'));
const Community = React.lazy(() => import('../components/Community'));
const OurStory = React.lazy(() => import('./OurStory'));
const ContactUs = React.lazy(() => import('./ContactUs'));
const JoinUs = React.lazy(() => import('./JoinUs'));


const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo
  }
};

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () =>
    dispatch({ type: REDIRECT })
});

const Footer = p => {
  return (
    <MDBFooter className="font-small pt-4 mt-4" dir="rtl" style={{
      background: headerBackground,
      color: "black",
      paddingTop: "3.5rem"
    }}>
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol style={{ textAlign: "center", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "10px" }}>
            <h5 className="title" style={{ textAlign: "center" }}>إخلاء مسؤولية</h5>
            <p>
              نهدف في منصة طفلي لتوعية الوالدين في كل ما يخص رعاية أطفالهم من الولادة إلى عمر السنتين.
            <br /> كما نتيح الفرصة لأفراد المجتمع بتبادل الخبرات والتجارب.
              <br /> وتخلي المنصة مسؤوليتها عن أي ضرر مادي أو أدبي قد يصيب أي زائر بسبب استخدامه للمعلومات المقدمة دون
              <br />  استناده لاستشارة طبية أو تربوية.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol style={{ textAlign: "center", paddingRight: "20px", paddingLeft: "20px" }}>
            <a href="https://twitter.com/Mybaby_AR" style={{ margin: "0.5rem", color: "#000" }}><MDBIcon fab icon="twitter" style={{ fontSize: "1.5rem" }} /></a>
            <a href="https://instagram.com/mybaby_ar" style={{ margin: "0.5rem", color: "#000" }}><MDBIcon fab icon="instagram" style={{ fontSize: "1.5rem" }} /></a>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          جميع الحقوق محفوظة: <a href="/"> منصة طفلي </a> &copy; {new Date().getFullYear()}
        </MDBContainer>
      </div>
    </MDBFooter >
  );
};

class App extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      store.dispatch(push(nextProps.redirectTo));
      this.props.onRedirect();
    }
  }

  componentWillMount() {
    const token = window.localStorage.getItem('jwt');
    if (token) {
      agent.setToken(token);
    }

    this.props.onLoad(token ? agent.Auth.current() : null, token);
  }

  render() {
    if (this.props.appLoaded) {
      return (
        <div>
          <Helmet>
            <meta charset="UTF-8" />
            <title>{web_title}</title>
            <meta name="description" content={web_desc} />
            <meta name="robots" content="follow, index" />
            <link rel="canonical" href={web_url} />
            <meta property="og:locale" content="ar_AR" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={web_title} />
            <meta property="og:description" content={web_desc} />
            <meta property="og:url" content={web_url} />
            <meta property="og:site_name" content={web_title} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={web_title} />
            <meta name="twitter:description" content={web_desc} />
          </Helmet>
          <Header
            appName={this.props.appName}
            currentUser={this.props.currentUser} />
          <div style={{ marginBottom: "180px", paddingBottom: "1.5rem" }}>
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/blog/:id" component={Blog} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/register-author" component={AuthorRegister} />
              <Route path="/our_story" component={OurStory} />
              <Route path="/contact" component={ContactUs} />
              <Route path="/join" component={JoinUs} />
              <Route path="/settings" component={Settings} />
              <Route path="/editor/:slug" component={Editor} />
              <Route path="/editor" component={Editor} />
              <Route exact path="/community" component={Community} />
              <Route path="/posteditor/:slug" component={PostEditor} />
              <Route path="/posteditor" component={PostEditor} />
              <Route path="/post/:id" component={Post} />
            </Switch>
          </Suspense>
          </div>

          {/* FOOTER */}
          <Footer style={{ paddingTop: "1000px" }} />
        </div>
      );
    }
    return (
      <div>
        <Header
          appName={this.props.appName}
          currentUser={this.props.currentUser} />
        {/* FOOTER */}
        <Footer style={{ marginTop: "1000px" }} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
