import {
  POSTEDITOR_PAGE_LOADED,
  POSTEDITOR_PAGE_UNLOADED,
  POST_SUBMITTED,
  ASYNC_START,
  ADD_TAG,
  REMOVE_TAG,
  UPDATE_FIELD_POSTEDITOR
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case POSTEDITOR_PAGE_LOADED:
      return {
        ...state,
        postSlug: action.payload ? action.payload.post.slug : '',
        // postSlug:  'hello-world',
        title: action.payload ? action.payload.post.title : '',
        description: action.payload ? action.payload.post.description : '',
        body: action.payload ? action.payload.post.body : '',
        tagInput: '',
        tagList: action.payload ? action.payload.post.tagList : []
      };
    case POSTEDITOR_PAGE_UNLOADED:
      return {};
    case POST_SUBMITTED:
      return {
        ...state,
        inProgress: null,
        errors: action.error ? action.payload.errors : null
      };
    case ASYNC_START:
      if (action.subtype === POST_SUBMITTED) {
        return { ...state, inProgress: true };
      }
      break;
    case ADD_TAG:
      return {
        ...state,
        tagList: state.tagList.concat([state.tagInput]),
        tagInput: ''
      };
    case REMOVE_TAG:
      return {
        ...state,
        tagList: state.tagList.filter(tag => tag !== action.tag)
      };
    case UPDATE_FIELD_POSTEDITOR:
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }

  return state;
};
