import React from 'react';

import {
  MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink,
  MDBNavbarToggler, MDBCollapse,
} from "mdbreact";
import { Route } from 'react-router-dom';

import { redText, headerBackground } from '../constants/colors'

const LoggedOutView = props => {
  if (!props.currentUser) {
    const path = window.location.pathname;
    return (
      <>
        <MDBNavbarNav right>
          <MDBNavItem>
            <MDBNavLink to="/" style={{ fontWeight: "700", color: path == "/" ? redText : null }}>الرئيسية</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/community" style={{ fontWeight: "700", color: path == "/community" ? redText : null }}>مجتمع طفلي</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/our_story" style={{ fontWeight: "700", color: path == "/our_story" ? redText : null }}>قصتنا</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/join" style={{ fontWeight: "700", color: path == "/join" ? redText : null }}>تطوع معنا</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/contact" style={{ fontWeight: "700", color: path == "/contact" ? redText : null }}>تواصل معنا</MDBNavLink>
          </MDBNavItem>
        </MDBNavbarNav>
        <MDBNavbarNav left>
          <MDBNavItem>
            <MDBNavLink to="/login" style={{ fontWeight: "700", color: path == "/login" ? redText : null }}>دخول</MDBNavLink>
          </MDBNavItem>
        </MDBNavbarNav>
      </>
    );
  }
  return null;
};

const LoggedInView = props => {
  if (props.currentUser) {
    const path = window.location.pathname;
    return (
      <>
        <MDBNavbarNav right>
          <MDBNavItem>
            <MDBNavLink to="/" style={{ fontWeight: "700", color: path == "/" ? redText : null }}>الرئيسية</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/community" style={{ fontWeight: "700", color: path == "/community" ? redText : null }}>مجتمع طفلي</MDBNavLink>
          </MDBNavItem>

          {props.currentUser.role === "writer" ?
            <MDBNavItem>
              <MDBNavLink to="/editor" style={{ fontWeight: "700", color: path.includes("/editor") ? redText : null }}>تدوينة جديدة</MDBNavLink>
            </MDBNavItem>
            :
            null}

          <MDBNavItem>
            <MDBNavLink to="/posteditor" style={{ fontWeight: "700", color: path.includes("/posteditor") ? redText : null }}>موضوع جديد</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/settings" style={{ fontWeight: "700", color: path == "/settings" ? redText : null }}>الإعدادات</MDBNavLink>
          </MDBNavItem>

          <MDBNavItem>
            <MDBNavLink to="/our_story" style={{ fontWeight: "700", color: path == "/our_story" ? redText : null }}>قصتنا</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/join" style={{ fontWeight: "700", color: path == "/join" ? redText : null }}>تطوع معنا</MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink to="/contact" style={{ fontWeight: "700", color: path == "/contact" ? redText : null }}>تواصل معنا</MDBNavLink>
          </MDBNavItem>
        </MDBNavbarNav>
        <MDBNavbarNav left>
          <MDBNavItem>
            <MDBNavLink to={`/@${props.currentUser.username}`} style={{ fontWeight: "700" }}>
              <img className="card-img-64 z-depth-1 mb-4"
                src={(!props.currentUser.image || /^\s*$/.test(props.currentUser.image)) ? "https://static.productionready.io/images/smiley-cyrus.jpg" : props.currentUser.image}
                alt="" />
            </MDBNavLink>
          </MDBNavItem>
        </MDBNavbarNav>
      </>
    );
  }

  return null;
};

class Header extends React.Component {

  state = {
    isOpen: false
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  componentDidMount = () => {
    var intervalId = setInterval(this.timer, 5000);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount = () => {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  timer = () => {
    // setState method is used to update the state
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <Route>
        <MDBNavbar light expand="md" dir="rtl" style={{
          background: headerBackground,
        }}>
          <MDBNavbarBrand href='/'>
            <img src={require('../static/logo_grey_bg.jpg')} height="100" alt="" />
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={this.toggleCollapse} />
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>


            <LoggedOutView currentUser={this.props.currentUser} />

            <LoggedInView currentUser={this.props.currentUser} />


          </MDBCollapse>
        </MDBNavbar>
      </Route >
    );
  }
}

export default Header;
